<template>
    <div>

        <PageHeader title="Customers">
            <div class="actionBtns">
                <button class="default reverse" @click="customer.addModal = true">Add customer</button>
            </div>
        </PageHeader>

        <ModalSimple title="Add Customer" @close="customer.item = {}" confirm="Add customer" :loading="isLoading(['saveCustomerDetails'])" @confirm="handleCustomerSave" v-model="customer.addModal">
            <CustomerDetails :customer="customer.item" :errors="customer.errors"/>
        </ModalSimple>

        <ModalSimple title="Edit Customer" @close="customer.item = {}" confirm="Save changes" :loading="isLoading(['saveCustomerDetails'])" @confirm="handleCustomerSave" v-model="customer.editModal">
            <CustomerDetails :customer="customer.item" :errors="customer.errors"/>
        </ModalSimple>

        <div class="wrapper">
            
            <Table class="customersTable" :cols="['Broker', 'Contact name', 'Contact Phone', 'Contact email', '']">
                <CustomerItem class="row" @edit="editItem(customer)" :key="customer._id" v-for="customer of customers" :customer="customer"/>
            </Table>
            
        </div>

    </div>
</template>

<script>
import CustomerDetails from '../components/customers/CustomerDetails.vue'
import CustomerItem from '../components/customers/CustomerItem.vue'

    export default {
        components: {
            CustomerDetails,
            CustomerItem
        },
        data() {
            return {
                customers: [],
                customer: {
                    addModal: false,
                    editModal: false,
                    item: {},
                    errors: {}
                }
            }
        },
        mounted() {
            this.getCustomers();
        },
        methods: {
            editItem(item) {
                this.customer.item = JSON.parse(JSON.stringify(item));
                this.customer.editModal = true;
            },
            getCustomers() {
                this.ajax('getListOfCustomers', {
                    method: 'GET',
                    url: '/userCustomers'
                }, (err, body) => {
                    if(err) return;
                    this.customers = body;
                });
            },
            handleCustomerSave() {
                this.customer.errors = {};
                this.ajax('saveCustomerDetails', {
                    method: 'POST',
                    url: '/userCustomers',
                    data: this.customer.item
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.customer.errors = body.errors;
                        else alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    this.customer.editModal = false;
                    this.customer.addModal = false;
                    this.customer.item = {};
                    this.getCustomers();
                });
            }
        },
    }
</script>

<style lang="scss">
.customersTable {
    .row {
        grid-template-columns: repeat(4, minmax(0, 1fr)) 40px !important;
    }
}
</style>


<style lang="scss" scoped>

.customersTable {
    .row {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr)) 40px !important;
        align-items: center;
        padding: 10px 20px;
        column-gap: 20px;

        &:nth-child(2n) {
            background: $bg;
        }
        &:last-child {
            border-radius: 0 0 $borderRadius $borderRadius;
        }
    }
}
</style>