<template>
    <div class="customerGrid">
        <BrokerSearch name="Customer/Broker name" :error="errors.details" v-model="customer.details"/>

        <Input v-model="customer.name" name="Contact name" :error="errors.name" placeholder="Contact name"/>
        <Input v-model="customer.phone" name="Contact phone" :error="errors.phone" placeholder="Contact phone" validate="phone"/>
        <Input v-model="customer.email" name="Contact email" :error="errors.email" placeholder="Contact email"/>

    </div>
</template>

<script>
import BrokerSearch from '../dispatch/BrokerSearch.vue'

    export default {
        components: {
            BrokerSearch
        },
        props: ['customer', 'errors'],
    }
</script>

<style lang="scss" scoped>
.customerGrid {
    display: grid;
    row-gap: 10px;
}
</style>