<template>
    <div>
        <div class="col">{{ customer.details ? customer.details.name : '-' }}</div>
        <div class="col">{{ customer.name }}</div>
        <div class="col">{{ customer.phone }}</div>
        <div class="col">{{ customer.email }}</div>
        <div class="col">
            <div class="actionBtn" :class="{ 'active' : showOptions }" v-click-outside="hideOptions">
                <button @click="switchOptions"><i class="fas fa-ellipsis-v"></i></button>
                <div class="menu" v-if="showOptions">
                    <a href="" @click.prevent="$emit('edit'); hideOptions()">Edit</a>
                    <a href="" @click.prevent="$emit('delete'); hideOptions()">Delete</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['customer'],
        data() {
            return {
                showOptions: false
            }
        },
        methods: {
            switchOptions() {
                this.showOptions = !this.showOptions;
            },
            hideOptions() {
                this.showOptions = false;
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>